.mobile-stay-list {
    margin-top: 30px;
    margin-bottom: 50px;
    position: relative;
    .mobile-stay-btn {
        position: absolute;
        right: 0;
        bottom: -25px;
    }
}

.mobile-type {
    color: #000;
	margin-top: 20px;
    margin-bottom: 25px;
}